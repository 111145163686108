import React from 'react';
import './ModelCard.css';

function ModelCard({ model, onClick }) {
  return (
    <div className="card" onClick={onClick}>
      <img src={model.image} alt={`${model.name} Logo`} />
      <h2>{model.name}</h2>
    </div>
  );
}

export default ModelCard;