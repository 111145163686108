import React from 'react';
import fluxrlogo from '../images/fluxr_logo.png';
import dashlogo from '../images/dash.png';

function HTMLComponent() {
  return (
    <div dangerouslySetInnerHTML={{ __html: `
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <link rel="stylesheet" href="layout.css"/>
      </head>
      <body>
        <header id="header" class="font-energetic min-h-[70px] flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-black text-sm py-3 sm:py-0 border-b-[1px] border-solid border-gray-600">
          <nav class="relative max-w-screen-2xl w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8" aria-label="Global">
            <div class="flex items-center justify-between flex-grow-1">
              <a class="flex text-xl gap-2 items-center justify-center font-semibold text-white" aria-label="Brand" href="/">
                <img src="https://yralcgckodgemrozhvhn.supabase.co/storage/v1/object/public/websites-data/logo/0Xcmj2GAuvmnqhgG" alt="Fluxr Logo" class="w-[40px] rounded-lg flex-1"/>
                <p class="text-[16px] md:text-[20px]">Fluxr</p>
              </a>
              <div class="sm:hidden">
                <button type="button" class="hs-collapse-toggle size-9 flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border 
                border-orange-500 text-orange-500 hover:border-orange-500 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1" data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
                  <svg class="hs-collapse-open:hidden flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="3" x2="21" y1="6" y2="6"></line>
                    <line x1="3" x2="21" y1="12" y2="12"></line>
                    <line x1="3" x2="21" y1="18" y2="18"></line>
                  </svg>
                  <svg class="hs-collapse-open:block hidden flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div id="navbar-collapse-with-animation" class="hs-collapse hidden overflow-hidden transition-all duration-300 sm:block">
              <div class="flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7">
                <a class="font-medium text-white hover:text-orange-500 sm:py-6" href="/">Home</a>
                <a class="font-medium text-white hover:text-orange-500 sm:py-6" href="plugpi.html">PlugPi</a>
                <a class="font-medium text-white hover:text-orange-500 sm:py-6" href="/dashboard">Dashboard</a>
                <a class="font-medium text-white hover:text-orange-500 sm:py-6" href="/station">Station</a>
              </div>
            </div>
          </nav>
        </header>
        <section id="hero-Ohaj6G" class="text-orange-100 font-energetic" style="background-image:linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('https://images.pexels.com/photos/4338102/pexels-photo-4338102.jpeg');background-size:cover;background-position:center;height:100%">
          <div class="container max-w-screen-2xl mx-auto flex px-5 py-24 pt-64 md:flex-row flex-col items-center">
            <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center">
              <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-orange-100">Welcome to Fluxr</h1>
              <p class="mb-8 leading-relaxed">Integrate seamlessly with Station, PlugPi, and Dashboard</p>
              <div class="flex justify-center">
                <a class="inline-flex text-orange-100 bg-orange-500 hover:bg-orange-700 border-0 py-2 px-6 focus:outline-none rounded text-lg" href="/station">Learn More</a>
              </div>
            </div>
          </div>
        </section>
        <div id="features-4XHSSo" class="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto font-ocean bg-black">
          <div class="relative bg-bg-black max-w-screen-2xl m-auto">
            <div class="relative flex w-full flex-col lg:flex-row items-center justify-center gap-4 lg:gap-12 flex-col lg:flex-row-reverse">
              <div class="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 w-full lg:w-1/2">
                <h2 class="text-2xl text-white font-bold sm:text-3xl">Our Products</h2>
                <nav class="grid lg:gap-10 mt-5 md:mt-10 gap-5" aria-label="Tabs" role="tablist">
                  <button type="button" class="hover:bg-gray-800 hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start p-4 md:p-5 rounded-xl dark:focus:outline-none dark:focus:ring-1 active" id="tabs-with-card-item-1" data-hs-tab="#tabs-with-card-1" aria-controls="tabs-with-card-1" role="tab">
                    <span class="flex">
                      <span class="grow ms-6">
                        <span class="block text-lg font-semibold text-white">Station</span>
                        <span class="block mt-1 text-gray-400">A platform for seamless API integration.</span>
                      </span>
                    </span>
                  </button>
                  <button type="button" class="hover:bg-gray-800 hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start p-4 md:p-5 rounded-xl dark:focus:outline-none dark:focus:ring-1" id="tabs-with-card-item-2" data-hs-tab="#tabs-with-card-2" aria-controls="tabs-with-card-2" role="tab">
                    <span class="flex">
                      <span class="grow ms-6">
                        <span class="block text-lg font-semibold text-white">PlugPi</span>
                        <span class="block mt-1 text-gray-400">An API Integration Platform designed to allow users to seamlessly integrate various plugins into a unified system.</span>
                      </span>
                    </span>
                  </button>
                  <button type="button" class="hover:bg-gray-800 hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start p-4 md:p-5 rounded-xl dark:focus:outline-none dark:focus:ring-1" id="tabs-with-card-item-3" data-hs-tab="#tabs-with-card-3" aria-controls="tabs-with-card-3" role="tab">
                    <span class="flex">
                      <span class="grow ms-6">
                        <span class="block text-lg font-semibold text-white">Dashboard</span>
                        <span class="block mt-1 text-gray-400">A user-friendly interface for effortless navigation and management of AI models.</span>
                      </span>
                    </span>
                  </button>
                </nav>
              </div>
              <div class="w-full lg:w-1/2">
                <div class="relative w-full">
                  <div>
                    <div id="tabs-with-card-1" class="tabpanel" aria-labelledby="tabs-with-card-item-1">
                      <img class="shadow-xl shadow-gray-800 max-h-[550px] rounded-xl dark:shadow-gray-900/[.2]" src="https://yralcgckodgemrozhvhn.supabase.co/storage/v1/object/public/websites-data/logo/VMhQwksdrN9ocVPY" alt="Image Description"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="video-Nie0aS" class="bg-black font-ocean px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div class="max-w-screen-2xl flex items-center justify-center mx-auto flex-col gap-4">
            <p class="text-2xl font-bold text-white">Latest from the Fluxr team</p>
            <div class="w-full lg:w-3/4">
              <div class="relative aspect-video w-full overflow-hidden">
                <iframe class="embed-responsive-item absolute bottom-0 left-0 right-0 top-0 h-full w-full" src="https://youtu.be/8t6JT_EieDk" allowfullscreen="" data-gtm-yt-inspected-2340190_699="true" id="video-Nie0aS-video"></iframe>
              </div>
            </div>
          </div>
        </div>
        <div id="form-SQFZsM" class="bg-black font-ocean px-4 py-10 sm:px-6 lg:px-8 lg:py-14">
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <div class="mb-10 md:mb-16">
              <h2 class="mb-4 text-center text-2xl font-bold text-white md:mb-6 lg:text-3xl">Contact Us</h2>
            </div>
            <form class="mx-auto grid max-w-screen-md gap-4 sm:grid-cols-2">
              <div>
                <label for="Name-for" class="mb-2 inline-block text-sm text-gray-400 sm:text-base">Name</label>
                <input name="Name-for" class="w-full rounded border bg-black text-gray-400 ring-blue-300 px-3 py-2 outline-none transition duration-100 focus:ring"/>
              </div>
              <div>
                <label for="Email-for" class="mb-2 inline-block text-sm text-gray-400 sm:text-base">Email</label>
                <input name="Email-for" class="w-full rounded border bg-black text-gray-400 ring-blue-300 px-3 py-2 outline-none transition duration-100 focus:ring"/>
              </div>
              <div class="sm:col-span-2">
                <label for="message" class="mb-2 inline-block text-sm text-gray-400 sm:text-base">Message</label>
                <textarea name="Message-for" class="h-64 w-full rounded border bg-black text-gray-400 ring-blue-300 px-3 py-2 outline-none transition duration-100 focus:ring"></textarea>
              </div>
              <div class="flex items-center justify-between sm:col-span-2">
                <button id="send-button" class="text-white bg-blue-500 hover:bg-blue-700 active:bg-blue-700 disabled:bg-gray-400 inline-block rounded-lg px-8 py-3 text-center text-sm font-semibold outline-none transition duration-100 focus-visible:ring md:text-base">Send</button>
              </div>
            </form>
          </div>
        </div>
        <div id="footer" class="bg-black font-energetic p-4 sm:pt-10 lg:p-12 border-t-[1px] border-solid border-gray-600">
          <footer class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <div class="mb-16 flex flex-col md:flex-row md:gap-12 gap-4 border-t pt-10 lg:gap-8 lg:pt-12">
              <div class="col-span-full lg:col-span-2">
                <div class="mb-4 lg:-mt-2">
                  <a class="text-white inline-flex items-center gap-2 text-xl font-bold md:text-2xl" aria-label="logo" href="/">
                    <img src="https://yralcgckodgemrozhvhn.supabase.co/storage/v1/object/public/websites-data/logo/0Xcmj2GAuvmnqhgG" alt="Fluxr Logo" class="w-[40px] rounded-lg flex-1"/>
                    <p class="max-w-[700px]">Fluxr</p>
                  </a>
                </div>
                <p class="text-gray-400 max-w-[500px] mb-6 sm:pr-8">Contact us for more information</p>
              </div>
              <div>
                <div class="text-gray-400 mb-4 font-bold tracking-widest">Navigation</div>
                <nav class="flex flex-col gap-4">
                  <div><a class="text-gray-400 hover:text-orange-500 active:text-orange-600 transition duration-100" href="/">Home</a></div>
                  <div><a class="text-gray-400 hover:text-orange-500 active:text-orange-600 transition duration-100" href="/plugpi">PlugPi</a></div>
                  <div><a class="text-gray-400 hover:text-orange-500 active:text-orange-600 transition duration-100" href="/dashboard">Dashboard</a></div>
                  <div><a class="text-gray-400 hover:text-orange-500 active:text-orange-600 transition duration-100" href="/station">Station</a></div>
                </nav>
              </div>
              <div>
                <div class="text-gray-400 mb-4 font-bold tracking-widest">Socials</div>
                <nav class="flex flex-col gap-4">
                  <div><a class="text-gray-400 hover:text-orange-500 active:text-orange-600 transition duration-100" href="https://github.com/fluxrcore">Github</a></div>
                  <div><a class="text-gray-400 hover:text-orange-500 active:text-orange-600 transition duration-100" href="https://www.youtube.com/">Youtube</a></div>
                </nav>
              </div>
            </div>
          </footer>
        </div>
      </body>
    ` }} />
  );
}

export default HTMLComponent;


