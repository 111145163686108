import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
//import './App.css';
//import './global.css';
import './layout.css'
import Dashboard from './components/Dashboard';
import GPT2 from './components/GPT2';
import Gemma from './components/Gemma';
import Llama2 from './components/Llama2';
import NotFound from './components/NotFound';
import HTMLComponent from './landing/Main';
import StationHTMLComponent from './landing/Station';
import hf_tgi_HTMLComponent from './landing/huggingface_tgi';
import plugpiHTMLComponent from './landing/plugpi';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<HTMLComponent />} />
	  <Route exact path="/station" element={<StationHTMLComponent />} />
	  <Route exact path="/plugpi" element={<plugpiHTMLComponent />} />
	  <Route path="/hf-tgi" element={<hf_tgi_HTMLComponent />} />
          <Route path="/gpt-2" element={<GPT2 />} />
          <Route path="/gemma" element={<Gemma />} />
          <Route path="/llama-2" element={<Llama2 />} />
          <Route path="/docs" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
