import React from 'react';
import './GPT2.css';
import { modelData } from '../data/modelData';
import gpt2Results from '../images/gpt2-results.png'; // Import the image

function GPT2() {
  const gpt2Data = modelData.find((model) => model.name === 'GPT-2');

  const copyCode = () => {
    const curlCommand = document.getElementById('curlCommand');
    const tempTextarea = document.createElement('textarea');
    tempTextarea.value = curlCommand.textContent;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);
    alert('Code copied to clipboard!');
  };

  return (
    <div className="gpt2-container">
      <div className="container">
        <div className="logo">
          <img src={gpt2Data.image} alt="GPT-2 Logo" />
        </div>
        <div className="content">
        <h1>GPT-2: Understanding the Next Generation of AI Text Generation</h1>
        <p>GPT-2, short for Generative Pre-trained Transformer 2, is an advanced natural language processing model developed by OpenAI. It represents a significant advancement in AI text generation and has garnered widespread attention for its remarkable capabilities.</p>
        
        <div className="curl-command">
          <h3>API Endpoint</h3>
          <select id="tierSelect">
            <option value="http://127.0.0.1:5000/gpt2-cpu">CPU</option>
            <option value="http://127.0.0.1:5000/gpt2-base">Base</option>
            <option value="http://127.0.0.1:5000/gpt2-fast">Fast</option>
          </select>
          <pre>
            <code id="curlCommand">
              {`curl -X POST -H "Content-Type: application/json" -d '{"input_text": ""}' http://127.0.0.1:5000/gpt2-cpu`}
            </code>
          </pre>
          <button className="copy-button" onClick={copyCode}>Copy Code</button>
        </div>

        <img src={gpt2Results} alt="GPT-2 Results" className="results-image" /> {/* Add the image */}

        <h2>Key Features of GPT-2</h2>
        <ul>
          <li>Large-Scale Training: GPT-2 is trained on a massive dataset, enabling it to generate coherent and contextually relevant text across a wide range of topics.</li>
          <li>Conditional Text Generation: Users can provide prompts or context to GPT-2, influencing the content it generates to align with specific themes or topics.</li>
          <li>High-Quality Outputs: GPT-2 produces high-quality text that closely resembles human-written content, making it suitable for various applications, including content creation, chatbots, and more.</li>
        </ul>
        <p>These features make GPT-2 a versatile tool with numerous practical applications in fields such as journalism, creative writing, customer service, and beyond.</p>
        <h2>Unlocking New Possibilities</h2>
        <p>As researchers and developers continue to explore the capabilities of GPT-2, we can expect to see even more innovative applications emerge. From personalized content generation to conversational AI, GPT-2 is paving the way for exciting advancements in the field of natural language processing.</p>
      </div>
      <a href="https://openai.com/research/better-language-models" className="button">Learn More about GPT-2</a>
    </div>
    </div>
  );
}

export default GPT2;