import React, { useState } from 'react';
import './Dashboard.css';
import ModelCard from './ModelCard';
import { modelData } from '../data/modelData';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const handleCardClick = (modelName) => {
    navigate(`/${modelName.toLowerCase()}`);
  };

  const handleLearnMoreClick = () => {
    navigate('/docs');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredModelData = modelData.filter((model) =>
    model.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="dashboard-container">
      <div className="container">
        <div className="dashboard-header">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Fluxr Logo" />
          <div className="header-content">
            <h1>Fluxr Dashboard</h1>
            <p>AI Simplified, Speed Amplified!</p>
            <button className="learn-more-button" onClick={handleLearnMoreClick}>
              Learn More
            </button>
          </div>
        </div>

        <div className="search-tab">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        <div className="cards-container">
          {filteredModelData.map((model) => (
            <ModelCard
              key={model.name}
              model={model}
              onClick={() => handleCardClick(model.name)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;