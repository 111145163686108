import React from 'react';
import fluxr_station_logo from '../images/fluxr_station_logo.png';
function StationHTMLComponent() {
  return (
    <div dangerouslySetInnerHTML={{ __html: `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Fluxr station</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background: linear-gradient(to bottom, #333333, #000000);
            color: #fff;
        }

        .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;
            position: relative;
        }

        header {
            background-color: rgba(0, 0, 0, 0.8);
            color: #fff;
            padding: 20px 0;
            width: 100%;
            z-index: 1000;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        nav ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        nav ul li {
            display: inline;
            margin-right: 20px;
        }

        nav ul li:last-child {
            margin-right: 0;
        }

        .back-arrow {
            font-size: 24px;
            color: white;
            text-decoration: none;
        }

        .back-arrow:hover {
            color: #ddd;
        }

        .hero {
            text-align: center;
            padding: 100px 0;
            background-color: transparent;
            position: relative;
            overflow: hidden;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
            z-index: -1;
        }

	.card-container {
	    display: grid;
	    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjusted minmax width for responsive cards */
	    gap: 70px;
	}


        .card {
            background-color: #333333;
            border-radius: 10px;
            padding: 40px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease; /* Added transition */
            color: inherit;
            text-decoration: none;
            overflow: hidden;
        }

        .card img {
            width: 100%;
            height: auto; /* Adjust height for the rectangular shape */
            object-fit: cover; /* Maintain aspect ratio */
            border-radius: 5px;
        }

        .card:hover {
            transform: scale(1.05); /* Added scaling effect on hover */
        }
        



        footer {
            background-color: rgba(0, 0, 0, 0.8);
            padding: 20px 0;
            text-align: center;
            position: relative;
            bottom: 0;
            width: 100%;
            box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
        }

        footer p {
            margin: 0;
            color: #fff;
        }

        .logo {
            max-width: 200px;
            margin-bottom: 20px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            animation: floatLogo 5s infinite alternate;
        }
        
        @keyframes floatLogo {
            0% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(-20px);
            }
            100% {
                transform: translateY(0);
            }
        }
    </style>
</head>
<body>
    <header>
        <div class="container">
            <nav class="nav-container">
                <ul>
                    <li><a href="index.html" class="back-arrow">&#8592; Back</a></li>
                </ul>
            </nav>
        </div>
    </header>

    <section class="hero">
        <div class="overlay"></div>
        <div class="container">
            <img src="${fluxr_station_logo}" alt="Logo" class="logo">
            <h1>Welcome to Fluxr station</h1>
            <p>Discover, manage, and share containerized applications and content.</p>
        </div>
    </section>

    <section id="images">
        <div class="container">
            <h2>Featured Images</h2>
        </div>
    </section>

    <div class="container">
        <div class="card-container">
            <div class="card">
                <a href="./fluxr_vllm.html" class="card">
                <h2>fluxr/vllm</h2>
                <img src="https://docs.vllm.ai/en/latest/_images/vllm-logo-text-light.png" alt="Fluxr/VLLM Image">
                </a>
            </div>
            <div class="card">
                <h2>fluxr/triton</h2>
                <img src="https://imgs.search.brave.com/uZxgri7EMo6TPKznfZnA5Z7B2SLVLnHG0YeO2LGtI4Y/rs:fit:860:0:0/g:ce/aHR0cHM6Ly93d3cu/bnZpZGlhLmNvbS9j/b250ZW50L252aWRp/YUdEQy91cy9lbl9V/Uy9hYm91dC1udmlk/aWEvbGVnYWwtaW5m/by9sb2dvLWJyYW5k/LXVzYWdlL19qY3Jf/Y29udGVudC9yb290/L3Jlc3BvbnNpdmVn/cmlkL252X2NvbnRh/aW5lcl8zOTI5MjE3/MDUvbnZfY29udGFp/bmVyXzQxMjA1NTQ4/Ni9udl9pbWFnZS5j/b3JlaW1nLnBuZy8x/NzAzMDYwMzI5MDk1/L252aWRpYS1sb2dv/LWhvcnoucG5n" alt="Second Image">
            </div>
            <div class="card">
                <a href="./hf-tgi.html" class="card">
                <h2>hf/tgi</h2>
                <img src="https://huggingface.co/datasets/huggingface/brand-assets/resolve/main/hf-logo.svg" style="width: 200px; height: auto; margin-top: -30px; margin-left: 40px;">
                </a>
            </div>
            <div class="card">
                <h2>My Fourth Image</h2>
                <img src="your_image_url_here" alt="Fourth Image">
            </div>
        </div>
    </div>

    <footer>
        <div class="container">
            <p>&copy; 2024 Docker Hub-like Page. All rights reserved.</p>
        </div>
    </footer>
</body>
</html>


    ` }} />
  );
}

export default StationHTMLComponent;
