import React from 'react';
import './NotFound.css';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="not-found-container">
      <div className="container">
        <h1>404 - Page Not Found</h1>
        <p>Oops! The page you're looking for does not exist.</p>
        <Link to="/" className="go-back-button">Go Back to Dashboard</Link>
      </div>
    </div>
  );
}

export default NotFound;