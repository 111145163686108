import React from 'react';
import './Gemma.css';
import { modelData } from '../data/modelData';
import gemmaResults from '../images/gemma-results.png';

function Gemma() {
  const gemmaData = modelData.find((model) => model.name === 'Gemma');

  const copyCode = () => {
    const curlCommand = document.getElementById('curlCommand');
    const tempTextarea = document.createElement('textarea');
    tempTextarea.value = curlCommand.textContent;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);
    alert('Code copied to clipboard!');
  };

  return (
    <div className="gemma-container">
      <div className="container">
        <div className="logo">
          <img src={gemmaData.image} alt="Gemma Logo" />
        </div>
        <div className="content">
        <h1>Gemma-7B: Empowering Open-Source AI</h1>
        <p>Gemma-7B is an open-source language model developed by Hugging Face. It aims to democratize access to powerful AI capabilities and foster collaboration within the AI community.</p>
        
        <div className="curl-command">
          <h3>API Endpoint</h3>
          <select id="tierSelect">
            <option value="http://127.0.0.1:5000/gemma-cpu">CPU</option>
            <option value="http://127.0.0.1:5000/gemma-base">Base</option>
            <option value="http://127.0.0.1:5000/gemma-fast">Fast</option>
          </select>
          <pre>
            <code id="curlCommand">
              {`curl -X POST -H "Content-Type: application/json" -d '{"input_text": ""}' http://127.0.0.1:5000/gemma-cpu`}
            </code>
          </pre>
          <button className="copy-button" onClick={copyCode}>Copy Code</button>
        </div>

        <img src={gemmaResults} alt="Gemma Results" className="results-image" /> {/* Add the image */}

        <h2>Key Features of Gemma-7B</h2>
        <ul>
            <li>Open-Source Availability: Gemma-7B is an open-source language model, allowing researchers and developers to freely access and utilize its capabilities.</li>
            <li>Multilingual Support: Gemma-7B is trained on a diverse dataset spanning multiple languages, enabling it to generate text in various languages and understand multilingual content.</li>
            <li>Efficient Training: Gemma-7B utilizes efficient training techniques, reducing the computational resources required to train and fine-tune the model.</li>
        </ul>
          <p>These features make Gemma-7B a valuable resource for the AI community, fostering collaboration, innovation, and the advancement of natural language processing technologies.</p>
          <h2>Empowering Collaborative AI Research</h2>
          <p>By providing an open-source language model, Gemma-7B empowers researchers and developers worldwide to collaborate and build upon its capabilities. It enables the creation of new applications, the exploration of novel techniques, and the sharing of knowledge within the AI community. With Gemma-7B, the possibilities for advancing natural language processing are vast and exciting.</p>
      </div>
      <a href="https://huggingface.co/blog/gemma" className="button">Learn More about Gemma</a>
    </div>
    </div>
  );
}

export default Gemma;