import React from 'react';
import './Llama2.css';
import { modelData } from '../data/modelData';
import gemmaResults from '../images/gemma-results.png'; // TODO: update image

function Llama2() {
  const llama2Data = modelData.find((model) => model.name === 'Llama-2');

  const copyCode = () => {
    const curlCommand = document.getElementById('curlCommand');
    const tempTextarea = document.createElement('textarea');
    tempTextarea.value = curlCommand.textContent;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);
    alert('Code copied to clipboard!');
  };

  return (
    <div className="llama2-container">
      <div className="container">
        <div className="logo">
          <img src={llama2Data.image} alt="Llama-2 Logo" />
        </div>
        <div className="content">
        <h1>Llama2-7b-chat: Engaging in Natural Conversations</h1>
        <p>Llama2-7b-chat is a state-of-the-art language model developed by Meta AI. It excels in generating human-like responses and engaging in natural conversations, making it a powerful tool for building conversational AI applications.</p>
        
        <div className="curl-command">
          <h3>API Endpoint</h3>
          <select id="tierSelect">
            <option value="http://127.0.0.1:5000/llama2-cpu">CPU</option>
            <option value="http://127.0.0.1:5000/llama2-base">Base</option>
            <option value="http://127.0.0.1:5000/llama2-fast">Fast</option>
          </select>
          <pre>
            <code id="curlCommand">
              {`curl -X POST -H "Content-Type: application/json" -d '{"input_text": ""}' http://127.0.0.1:5000/llama2-cpu`}
            </code>
          </pre>
          <button className="copy-button" onClick={copyCode}>Copy Code</button>
        </div>

        <img src={gemmaResults} alt="Gemma Results" className="results-image" />

        <h2>Key Features of Llama2-7b-chat</h2>
        <ul>
            <li>Natural Conversation Generation: Llama2-7b-chat excels in generating human-like responses, enabling engaging and coherent conversations.</li>
            <li>Contextual Understanding: Llama2-7b-chat maintains context throughout the conversation, allowing for more meaningful and relevant interactions.</li>
            <li>Multilingual Support: Llama2-7b-chat supports multiple languages, making it suitable for building conversational AI applications for a global audience.</li>
        </ul>
        <p>These features make Llama2-7b-chat a powerful tool for creating chatbots, virtual assistants, and other conversational AI applications that can engage users in natural and intuitive ways.</p>
        <h2>Revolutionizing Conversational AI</h2>
        <p>With its advanced natural language processing capabilities, Llama2-7b-chat is revolutionizing the field of conversational AI. It enables the development of AI-powered agents that can understand and respond to user queries, provide personalized recommendations, and offer seamless customer support. As conversational AI continues to evolve, Llama2-7b-chat is at the forefront, driving innovation and shaping the future of human-machine interaction.</p>
        </div>
      <a href="https://ai.meta.com/blog/large-language-model-llama-meta-ai/" className="button">Learn More about Llama-2</a>
    </div>
    </div>
  );
}

export default Llama2;