import gpt2Logo from '../images/gpt2-logo.png';
import gemmaLogo from '../images/gemma-logo.png';
import llama2Logo from '../images/llama2-logo.png';

export const modelData = [
    {
      name: 'GPT-2',
      image: gpt2Logo,
    },
    {
      name: 'Gemma',
      image: gemmaLogo,
    },
    {
      name: 'Llama-2',
      image: llama2Logo,
    },
];